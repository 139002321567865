// List Csss Form 
.list-group-item-primary {
  color: #213770;
  background-color: #c9d5f4
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #213770;
  background-color: #b4c5f0
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #213770;
  border-color: #213770
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41
}

.list-group-item-success {
  color: #1e6641;
  background-color: #c8eedb
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1e6641;
  background-color: #b5e8ce
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1e6641;
  border-color: #1e6641
}

.list-group-item-info {
  color: #0b5885;
  background-color: #bee7ff
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0b5885;
  background-color: #a5deff
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0b5885;
  border-color: #0b5885
}

.list-group-item-warning {
  color: #806013;
  background-color: #fdebc2
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #806013;
  background-color: #fce3a9
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #806013;
  border-color: #806013
}

.list-group-item-danger {
  color: #71132a;
  background-color: #f4c2ce
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #71132a;
  background-color: #f0acbd
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #71132a;
  border-color: #71132a
}

.list-group-item-light {
  color: #7c7c7c;
  background-color: #fafafa
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #ededed
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21
}

.list-group-item-focus {
  color: #23212c;
  background-color: #cbcacf
}

.list-group-item-focus.list-group-item-action:hover,
.list-group-item-focus.list-group-item-action:focus {
  color: #23212c;
  background-color: #bebdc3
}

.list-group-item-focus.list-group-item-action.active {
  color: #fff;
  background-color: #23212c;
  border-color: #23212c
}

.list-group-item-alternate {
  color: #3f2848;
  background-color: #d9cdde
}

.list-group-item-alternate.list-group-item-action:hover,
.list-group-item-alternate.list-group-item-action:focus {
  color: #3f2848;
  background-color: #cdbed4
}

.list-group-item-alternate.list-group-item-action.active {
  color: #fff;
  background-color: #3f2848;
  border-color: #3f2848
}
.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
 
}
.card-header+.list-group .list-group-item:first-child {
  border-top: 0
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef
}

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3f6ad8;
  border-color: #007bff
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0
}

.list-group-item-primary {
  color: #213770;
  background-color: #c9d5f4
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #213770;
  background-color: #b4c5f0
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #213770;
  border-color: #213770
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41
}

.list-group-item-success {
  color: #1e6641;
  background-color: #c8eedb
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #1e6641;
  background-color: #b5e8ce
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #1e6641;
  border-color: #1e6641
}

.list-group-item-info {
  color: #0b5885;
  background-color: #bee7ff
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0b5885;
  background-color: #a5deff
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0b5885;
  border-color: #0b5885
}

.list-group-item-warning {
  color: #806013;
  background-color: #fdebc2
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #806013;
  background-color: #fce3a9
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #806013;
  border-color: #806013
}

.list-group-item-danger {
  color: #71132a;
  background-color: #f4c2ce
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #71132a;
  background-color: #f0acbd
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #71132a;
  border-color: #71132a
}

.list-group-item-light {
  color: #7c7c7c;
  background-color: #fafafa
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #ededed
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21
}

.list-group-item-focus {
  color: #23212c;
  background-color: #cbcacf
}

.list-group-item-focus.list-group-item-action:hover,
.list-group-item-focus.list-group-item-action:focus {
  color: #23212c;
  background-color: #bebdc3
}

.list-group-item-focus.list-group-item-action.active {
  color: #fff;
  background-color: #23212c;
  border-color: #23212c
}

.list-group-item-alternate {
  color: #3f2848;
  background-color: #d9cdde
}

.list-group-item-alternate.list-group-item-action:hover,
.list-group-item-alternate.list-group-item-action:focus {
  color: #3f2848;
  background-color: #cdbed4
}

.list-group-item-alternate.list-group-item-action.active {
  color: #fff;
  background-color: #3f2848;
  border-color: #3f2848
}
.list-group-item:hover {
  z-index: initial
}
.todo-list-wrapper .list-group-item:hover .todo-indicator {
  opacity: .9
}

.rm-list-borders .list-group-item {
  border: 0;
  padding: .5rem 0
}

.rm-list-borders-scroll .list-group-item {
  border: 0;
  padding-right: 1.125rem
}

.bg-transparent.list-group-item {
  border-color: transparent
}


.todo-list-wrapper .list-group-item:hover .todo-indicator {
  opacity: .9
}


.todo-list-wrapper .todo-indicator {
  position: absolute;
  width: 4px;
  height: 60%;
  border-radius: .3rem;
  left: .625rem;
  top: 20%;
  opacity: .6;
  transition: opacity .2s
}
.widget-content .widget-content-wrapper {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center
}


// ***************** Badge *************************
.badge-primary {
  color: #fff;
  background-color: #3f6ad8
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #2651be
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62
}

.badge-success {
  color: #fff;
  background-color: #3ac47d
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #2e9d64
}

.badge-info {
  color: #fff;
  background-color: #16aaff
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #0090e2
}

.badge-warning {
  color: #212529;
  background-color: #f7b924
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #212529;
  background-color: #e0a008
}

.badge-danger {
  color: #fff;
  background-color: #d92550
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #ad1e40
}

.badge-light {
  color: #212529;
  background-color: #eee
}

a.badge-light:hover,
a.badge-light:focus {
  color: #212529;
  background-color: #d5d5d5
}

.badge-dark {
  color: #fff;
  background-color: #343a40
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124
}

.badge-focus {
  color: #fff;
  background-color: #444054
}

a.badge-focus:hover,
a.badge-focus:focus {
  color: #fff;
  background-color: #2d2a37
}

.badge-alternate {
  color: #fff;
  background-color: #794c8a
}

a.badge-alternate:hover,
a.badge-alternate:focus {
  color: #fff;
  background-color: #5c3a69
}

.custom-checkbox.custom-control {
  margin-left: 12px;
}