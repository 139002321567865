// Fontawesome

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/variables";

@import "~@fortawesome/fontawesome-free/scss/solid";

@import "~@fortawesome/fontawesome-free/scss/mixins";
@import "~@fortawesome/fontawesome-free/scss/core";
@import "~@fortawesome/fontawesome-free/scss/larger";
@import "~@fortawesome/fontawesome-free/scss/fixed-width";
@import "~@fortawesome/fontawesome-free/scss/list";
@import "~@fortawesome/fontawesome-free/scss/bordered-pulled";
@import "~@fortawesome/fontawesome-free/scss/animated";
@import "~@fortawesome/fontawesome-free/scss/rotated-flipped";
@import "~@fortawesome/fontawesome-free/scss/stacked";
@import "~@fortawesome/fontawesome-free/scss/icons";
@import "~@fortawesome/fontawesome-free/scss/screen-reader";

.fa-2x {
  line-height: 2;
}