@import './form/form-listing.scss';

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "components/bootstrap5/functions";

// 2. Include any default variable overrides here

@import "themes/layout-variables";

// 3. Include remainder of required Bootstrap stylesheets

@import "components/bootstrap5/variables";
@import "components/bootstrap5/mixins";
@import "components/bootstrap5/utilities";

// BOOTSTRAP 5 IMPORTS

@import "components/bootstrap5/root";
@import "components/bootstrap5/reboot";
@import "components/bootstrap5/type";
@import "components/bootstrap5/images";
@import "components/bootstrap5/containers";
@import "components/bootstrap5/grid";
@import "components/bootstrap5/tables";
@import "components/bootstrap5/forms";
@import "components/bootstrap5/buttons";
@import "components/bootstrap5/transitions";
@import "components/bootstrap5/dropdown";
@import "components/bootstrap5/button-group";
@import "components/bootstrap5/nav";
@import "components/bootstrap5/navbar";
@import "components/bootstrap5/card";
@import "components/bootstrap5/accordion";
@import "components/bootstrap5/breadcrumb";
@import "components/bootstrap5/pagination";
@import "components/bootstrap5/badge";
@import "components/bootstrap5/alert";
@import "components/bootstrap5/progress";
@import "components/bootstrap5/list-group";
@import "components/bootstrap5/close";
@import "components/bootstrap5/toasts";
@import "components/bootstrap5/modal";
@import "components/bootstrap5/tooltip";
@import "components/bootstrap5/popover";
@import "components/bootstrap5/carousel";
@import "components/bootstrap5/spinners";
@import "components/bootstrap5/offcanvas";
@import "components/bootstrap5/jumbotron";
@import "components/bootstrap5/helpers";
@import "components/bootstrap5/utilities/api";

// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/accordions";
@import "elements/modals";
@import "elements/timeline";
@import "elements/popovers-tooltips";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";

// PAGES
@import "pages/userpages";

// COMPONENTS

// Perfect Scrollbar

@import "components/perfect-scrollbar/perfect-scrollbar";

// Hamburger button

@import "components/hamburgers/hamburgers";
//
// Icons

@import "components/icons/p7icons";

// Responsive

@import "layout/responsive/responsive-base";

// DEMO
//
@import "demo-ui/demo";



// Stepper
.stepper {
    .nav-tabs {
        position: relative;
    }
    .nav-tabs > li {
        width: 25%;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            background: #f1f1f1;
            display: block;
            width: 100%;
            height: 5px;
            top: 30px;
            left: 50%;
            z-index: 1;
        }
        &.completed {
            &::after {
                background: #34bc9b;
            }
        }
        &:last-child {
            &::after {
                background: transparent;
            }
        }
        &.active:last-child {
            .round-tab {
                background: #34bc9b;
                &::after {
                    content: '✔';
                    color: #fff;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: 0;
                    display: block;
                }
            }
        }
    }
    .nav-tabs [data-toggle='tab'] {
        width: 25px;
        height: 25px;
        margin: 20px auto;
        border-radius: 100%;
        border: none;
        padding: 0;
        color: #f1f1f1;
    }
    .nav-tabs [data-toggle='tab']:hover {
        background: transparent;
        border: none;
    }
    .nav-tabs > .active > [data-toggle='tab'], .nav-tabs > .active > [data-toggle='tab']:hover, .nav-tabs > .active > [data-toggle='tab']:focus {
        color: #34bc9b;
        cursor: default;
        border: none;
    }
    .tab-pane {
        position: relative;
        padding-top: 50px;
    }
    .round-tab {
        width: 25px;
        height: 25px;
        line-height: 22px;
        display: inline-block;
        border-radius: 25px;
        background: #fff;
        border: 2px solid #34bc9b;
        color: #34bc9b;
        z-index: 2;
        position: absolute;
        left: 0;
        text-align: center;
        font-size: 14px;
        
    }
    .completed .round-tab{
    	background: #34bc9b;
    	&::after {
    		content: '✔';
    		color: #fff;
    		position: absolute;
    		left: 0;
    		right: 0;
    		margin: 0 auto;
    		top: 0;
    		display: block;
        }
    }
    .active .round-tab {
        background: #fff;
        border: 2px solid #34bc9b;
        &:hover {
            background: #fff;
            border: 2px solid #34bc9b;
        }
        &::after {
            display: none;
        }
    }
    .disabled .round-tab {
        background: #fff;
        color: #f1f1f1;
        border-color: #f1f1f1;
        &:hover {
            color: #4dd3b6;
            border: 2px solid #a6dfd3;
        }
        &::after {
            display: none;
        }
    }
}