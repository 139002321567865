$theme-color: #d96323;

ngb-typeahead-window {
  width: 67.9% !important;
}

ngb-typeahead-window>.dropdown-item.active {
  background-color: $theme-color;
}

// header css

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 24px;
  height: 2px;
  background-color: $theme-color  !important;
  border-radius: 10px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.search-wrapper .input-holder .search-icon span::after {
  width: 14px;
  height: 14px;
  left: 4px;
  top: 0;
  border-radius: 16px;
  border: 2px solid #d96323 !important;
}

.search-wrapper .input-holder .search-icon span::before {
  width: 4px;
  height: 11px;
  left: 9px;
  top: 13px;
  border-radius: 2px;
  background: #d96323 !important;
}

// Header Css End

// Sidebar  Css

.app-sidebar__heading {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 0.75rem 0;
  font-weight: 700;
  color: #d96323 !important;
  white-space: nowrap;
  position: relative;
}

.vertical-nav-menu ul>li>a.mm-active {
  color: #d96323 !important;
  background: #e0f3ff;
  font-weight: 700;
}

.vertical-nav-menu ul>li>a:hover {
  color: #d96323 !important;
}

// Sidebar Css END

// Main page Css

// Main page Css eND

// variable Css

//variable css end

.forms-wizard li.active em {
  background: $theme-color;
  color: #fff;
}

.forms-wizard li.active::after,
.forms-wizard li.active::before {
  background: $theme-color;
}

.btn-primary {
  color: #fff !important;
  background-color: $theme-color  !important;
  border-color: $theme-color  !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: $theme-color  !important;
  border-color: $theme-color  !important;
}

.form-heading {
  font-size: 1.1rem;
  margin: 0;
  color: $theme-color;
}

// ------------------------------------ import excel css start ---------------------------------------

.upload-dragndrop {
  margin-bottom: 2rem;

  // Drag Drop

  .drag-and-drop-uploader {
    .container {
      padding: 50px 10%;
    }

    .box {
      position: relative;
      background: #ffffff;
      width: 100%;
    }

    .box-header {
      color: rgb(0, 0, 0);
      padding: 10px;
      position: relative;
      border-bottom: 1px solid #f4f4f4;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .search-wrapper.active {
      width: 70% !important;
    }

    .search-wrapper.active .input-holder {
      width: 100% !important;
    }

    .box-tools {
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .dropzone-wrapper {
      border: 2px dashed #dc6622;
      color: #000000;
      position: relative;
      height: 150px;
      border-radius: 8px;
    }

    .dropzone-desc {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      text-align: center;
      width: 40%;
      top: 30px;
      font-size: 16px;
    }

    .dropzone,
    .dropzone:focus {
      position: absolute;
      outline: none !important;
      width: 100%;
      height: 150px;
      cursor: pointer;
      opacity: 0;
    }

    .dropzone-wrapper:hover,
    .dropzone-wrapper.dragover {
      background: #ecf0f5;
    }

    .preview-zone {
      text-align: center;
    }

    .preview-zone .box {
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 0;
    }

    .main-preview {
      display: flex;
    }

    .main-preview .preview-img img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .preview-img {
      position: relative;
    }

    .preview-img select {
      width: 100%;
    }

    .image-feature {
      display: flex;
      justify-content: space-between;
    }

    .image-feature-inner img {
      width: 16px !important;
      height: 5px !important;
      object-fit: unset !important;
      cursor: pointer;
    }

    .image_icon img {
      width: 20px !important;
      height: 20px !important;
    }

    .main-preview {
      padding: 20px 0px;
      overflow: scroll;
      overflow-y: unset;
    }

    .preview-img {
      border: none;
      margin-right: 20px;
      box-shadow: 0px 2px 9px 0px #0000002e;
      border-radius: 10px;
      padding: 10px;
      width: 22%;
    }
  }

  .form-group {
    background-color: white;
    margin: 2rem 0px;
    padding: 20px;
    border-radius: 9px;
    box-shadow: 0px 0px 9px 0px #0000004f;
  }

  .allowed {
    font-size: 13px;
    padding: 5px 0px;
  }

  .allowed::before {
    content: "*";
    color: red;
    font-size: 14px;
  }

  // table css

  .preview_data {
    padding: 20px 0px 0px 0px;
    border: 1px solid silver;
    border-radius: 9px 9px 0px 0px;
    background-color: white;
  }

  .preview_data,
  .toggle_main {
    display: flex;
    flex-wrap: wrap;
  }

  .bdr-tl {
    border-right: 1px solid silver;
    margin-bottom: 20px;
  }

  .name {
    font-weight: bold;
    font-size: 12px;
    margin: 0;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .preview-table-data i::before {
    font-family: "font awesome 5 free";
    font-style: normal;
  }

  .toggle_show {
    margin-top: 20px;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    height: 300px;
    background-color: #fffbfa;
    padding-bottom: 20px;
  }

  .toggle_show .col-md-3 {
    margin-top: 17px;
  }

  .toggle_hide {
    cursor: pointer;
  }

  .btn-danger {
    color: #fff;
    background-color: #dc6622 !important;
    border-color: #dc6622 !important;
  }
}

// ------------------------------------ IMPORT EXCEL CSS END ---------------------------------------

// ------------------------------------ LTE LISTING CSS START ---------------------------------------


// ------------------------------------ LTE LISTING CSS END ---------------------------------------


.btn-warning {
  color: #f4f4f4 !important;
}